import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class PricebookDataAccess {
  savePricebook(data: any) {
    return commonDataAccess.postData("pricebook/save/pricebook", data);
  }
  updatePricebookPrice(id: any, type: any, priceChange: any) {
    return commonDataAccess.postData("pricebook/update/pricechange", null, {
      id: id,
      type: type,
      priceChangeInPercentage: priceChange,
    });
  }
  savePricebookDetail(data: any) {
    return commonDataAccess.postData("pricebook/save/pricebookdetail", data);
  }
  getPricebook(pricebookId: any) {
    return commonDataAccess.getData("pricebook/get/pricebook", {
      id: pricebookId,
    });
  }
  getAll = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
    pricebooktype;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData(
      "pricebook/get/" + params.pricebooktype + "PricebookList",
      parameters
    );
  };
  // deleteById = (id: number) => {
  //   return commonDataAccess.deleteData("pricebook/delete", {
  //     id: id,
  //   });
  // };
  deleteById = async (ids: string) => {
    var result = await commonDataAccess.deleteData("pricebook/delete", {
      ids: ids,
    });
    return result;
  };
  deletepriceBookDetailsById = async (id: any) => {
    var result = await commonDataAccess.deleteData(
      "pricebook/deletePriceBookDetails",
      { id: id }
    );
    return result;
  };
}

export default new PricebookDataAccess();
