import { createAsyncThunk } from "@reduxjs/toolkit";
import complaintCodeDataaccess from "src/dataAccess/complaints/complaintCode.dataaccess";

export const GetComplaintCategoryList = createAsyncThunk<any, any>(
  "[Complaint Category] Get complaint Category list",
  async (parameter, thunkAPI) => {
    const result = await complaintCodeDataaccess
      .getList(parameter)
      .then((res) => res);
    return result;
  }
);
