import { createReducer } from "@reduxjs/toolkit";
import { GetComplaintCategoryList } from "./complaintcategory.action";

export interface IComplaintCategoryState {
  list: any[];
  dataCount: number;
  isLoadingList: boolean;
}
const initialState: Partial<IComplaintCategoryState> = {
  list: [],
  dataCount: 0,
};
export const complaintCategoryReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(GetComplaintCategoryList.pending, (state, action) => {
        state = {
          ...state,
          isLoadingList: true,
        };
        return state;
      })

      .addCase(GetComplaintCategoryList.fulfilled, (state, action) => {
        state = {
          ...state,
          list: action.payload?.data,
          dataCount: action.payload?.dataCount,
          isLoadingList: false,
        };
        return state;
      });
  }
);
