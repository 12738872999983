import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class ProcedureJobDataAccess {
  SaveProductCaptureValue(data: any) {
    return commonDataAccess.postData(
      "procedureJob/save/ProductGoodsInCaptureValue",
      data
    );
  }
  getProductCaptureValue(id: any) {
    return commonDataAccess.getData(
      "procedureJob/get/ProductGoodsInCaptureValue",
      { jobDetailId: id }
    );
  }
  SaveAdditionalComment(data: any) {
    return commonDataAccess.postData(
      "procedureJob/save/additional-comment",
      data
    );
  }
  getList = (params: {
    typeProcedure;
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    console.log(params.filters);
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    console.log(_filters);
    let parameters = {
      typeProcedure: params.typeProcedure,
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return commonDataAccess.getData("procedureJob/getall", parameters);
  };
  getProcedureJobDetailById = (id: number) => {
    return commonDataAccess.getData("procedureJob/get/jobwithdetails", {
      id: id,
    });
  };

  getChecklistByJobDetailId = (jobDetailId: number) => {
    return commonDataAccess.getData("procedureJob/get/checklistbyjobdetailId", {
      jobDetailId: jobDetailId,
    });
  };
  deleteJobs = (jobIds: string) => {
    return commonDataAccess.deleteData("procedureJob/delete/Jobs", {
      jobIds: jobIds,
    });
  };
  confirmJobs = (jobIds: string) => {
    return commonDataAccess.putData("procedureJob/confirmall", null, {
      jobIds: jobIds,
    });
  };
  printReport = (jobId: any) => {
    return commonDataAccess.downloadFile(`procedureJob/printReport/${jobId}`);
  };
  printJobReport = (jobId: any) => {
    return commonDataAccess.downloadFile(
      `procedureJob/printJobReport/${jobId}`
    );
  };

  printJobTabletFormatReport = (organizationId: any, jobId: any) => {
    return commonDataAccess.downloadFile(
      `JobReport/printJobTabletFormatReport/${organizationId}/${jobId}`,
      {},
      "Legacy"
    );
  };
  printGoodsInJobReport = (organizationId: any, jobId: any) => {
    return commonDataAccess.downloadFile(
      `JobReport/printGoodsInJobReport/${organizationId}/${jobId}`,
      {},
      "Legacy"
    );
  };
  printAuditReport = (organizationId: any, jobId: any) => {
    return commonDataAccess.downloadFile(
      `JobReport/printAuditReport/${organizationId}/${jobId}`,
      {},
      "Legacy"
    );
  };
  printAuditBySiteReport = (
    organizationId: any,
    data: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: `JobReport/printAuditBySiteReport/${organizationId}`,
      payload: data,
      onDowloadProgress: downloadProgress,
      type: "Legacy",
    });
  };
  printNCR = (organizationId: any, jobId: any) => {
    return commonDataAccess.downloadFile(
      `JobReport/printNCRReport/${organizationId}/${jobId}`,
      {},
      "Legacy"
    );
  };

  updateCaptureValue = (jobDetailId: any, captureValue: any) => {
    return commonDataAccess.postData("procedureJob/update/capturevalue", null, {
      jobDetailId: jobDetailId,
      captureValue: captureValue,
    });
  };

  moveDataToArchived = (jobIds: any, isArchived: any) => {
    return commonDataAccess.postData("procedureJob/movetoarchiveddata", null, {
      jobIds: jobIds,
      isArchived: isArchived,
    });
  };

  approveAndNext = (jobId: any) => {
    return commonDataAccess.putData("procedureJob/approve-and-next", null, {
      jobId: jobId,
    });
  };
  downloadExportDataToExcel = (
    payload: any,

    downloadProgress: (progress: any) => void
  ) => {
    console.log(payload);
    return commonDataAccess.downloadPost({
      url: "ProcedureJob/Get/ExportStepCollectedData",
      payload: payload,
      onDowloadProgress: downloadProgress,
    });
  };
  downloadBatchCodeReport = (
    organizationId,
    payload: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: `PurchaseOrderReport/get/BatchCodeReport/${organizationId}`,
      payload: payload,
      onDowloadProgress: downloadProgress,
      type: "Legacy",
    });
  };
  getSupplierEmailByJobId = (jobId: any) => {
    return commonDataAccess.getData(`procedureJob/get/supplierEmail/${jobId}`);
  };

  getInProgressDetailsById = (id: number) => {
    return commonDataAccess.getData("procedureJob/get/inProgressDetails", {
      id: id,
    });
  };
}

export default new ProcedureJobDataAccess();
