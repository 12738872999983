import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class DepartmentDataAccess {
  saveCheckPermission(userCheckIds: string, webUserIds: string, id: any) {
    return commonDataAccess.postData("department/save/permittedChecks", null, {
      id: id,
      userCheckIds: userCheckIds,
      webUserCheckIds: webUserIds,
    });
  }
  getPermittedChecks(id: any) {
    return commonDataAccess.getData("department/get/permittedChecks", {
      id: id,
    });
  }
  getList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("department/getall", parameters);
  };
  getAllDepartments = () => {
    return commonDataAccess.getData("department/get/list");
  };
  getAllDepartmentForDropdown = () => {
    return commonDataAccess.getData("department/get/departmentlist");
  };
  getDepartmentGeneral = (id: any) => {
    return commonDataAccess.getData(`department/get/departmentgeneral/${id}`);
  };
  saveDepartmentGeneral = (payload: any) => {
    return commonDataAccess.postData(
      "department/save/departmentgeneral",
      payload
    );
  };
  getDashboardPermission = (id: any) => {
    return commonDataAccess.getData(`department/get/dashboardpermission/${id}`);
  };
  saveDashboardPermission = (payload: any) => {
    return commonDataAccess.postData(
      "department/save/dashboardpermission",
      payload
    );
  };
  getMenuPermission = (id: any) => {
    return commonDataAccess.getData(`department/get/menupermission/${id}`);
  };
  saveMenuPermission(payload: any) {
    return commonDataAccess.postData("department/save/menupermission", payload);
  }
  deleteDepartment = (departmentIds: string) => {
    return commonDataAccess.deleteData(`department/delete/${departmentIds}`);
  };
  getSubDepartmentByDepartmentId = (departmentId: any) => {
    return commonDataAccess.getData(
      `department/get/subDepartmentByDepartmentId/${departmentId}`
    );
  };
  getSubDepartmentsByDeptIds = (departmentIds: string) => {
    return commonDataAccess.getData(
      `department/get/subDepartmentByDepartmentIds/${departmentIds}`
    );
  };
}

export default new DepartmentDataAccess();
